.carousel .slide {
    background: transparent;
    text-align: left;
}

.carousel .control-dots .dot {
    outline: none;
}

.carousel .control-dots {
    position: static;
}