.facebook {
	background-color: #4c69ba;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
		rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
	padding: 0px;
	border-radius: 2px;
	border: 1px solid transparent;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	width: 203px;
	height: 2.75rem;
	color: white;
}

.facebook:hover {
	cursor: pointer;
}

.fa.fa-facebook {
	margin-right: 12px;
}
